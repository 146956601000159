import React from "react"
import styled from "styled-components";

const FlexItemLayout = styled.div`
  padding: 0px;
  margin: 10px;
  @media (min-width:801px) {
    margin: 50px;
    &:nth-child(1) {
      flex-shrink: 0;        
      width: 62%;
    }
    &:nth-child(2) {
      flex: 1;        
    }
  }
`

const FlexItem =({ children }) => {
  return <FlexItemLayout>{children}</FlexItemLayout>
}

export default FlexItem 