import React from "react"
import styled from "styled-components";

const FlexContainerLayout = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width:801px){
    flex-direction: row;
  }
`
const FlexContainer = ({ children }) => {
  return <FlexContainerLayout>{children}</FlexContainerLayout>
}

export default FlexContainer