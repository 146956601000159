import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const SEO = ({ context, meta, lang, withSchema, noindex }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  )

  const title = context.data.name_event
  const metaDescription = context.data.desc_event || site.siteMetadata.description

  let img, start_date_event, end_date_event, location_event = undefined

  if(withSchema === true) {
     if(context.data.teaser_img_event !== undefined || context.data.teaser_img_event.localFiles !== undefined) {
      img = site.siteMetadata.siteUrl + "" + context.data.teaser_img_event.localFiles[0].childImageSharp.fluid.src    
    }
  
    if(context.data.start_date_event !== undefined) {
      start_date_event = context.data.start_date_event
    }
  
    if(context.data.end_date_event !== undefined) {
      end_date_event = context.data.end_date_event
    }
  
    if(context.data.location_event !== undefined) {
      location_event = context.data.location_event
    }
  }

  return (
    <React.Fragment>
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={title}
        titleTemplate={`%s | ${site.siteMetadata.title}`}
        meta={[
          {
            name: `description`,
            content: metaDescription,
          },
          {
            property: `og:title`,
            content: title,
          },
          {
            property: `og:description`,
            content: metaDescription,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:creator`,
            content: site.siteMetadata.author,
          },
          {
            name: `twitter:title`,
            content: title,
          },
          {
            name: `twitter:description`,
            content: metaDescription,
          },
        ].concat(meta)}
      />

      {(withSchema === true) ? (
        <Helmet>
          <script type="application/ld+json">
            {`
            {
              "@context": "https://schema.org",
              "@type": "Event",
              "name": "${title}",
              "startDate": "${start_date_event}",
              "endDate": "${end_date_event}",
              "eventAttendanceMode": "https://schema.org/OfflineEventAttendanceMode",
              "eventStatus": "https://schema.org/EventScheduled",
              "location": {
                "@type": "Place",
                "name": "${location_event}",
                "address": {
                  "@type": "PostalAddress",
                  "streetAddress": "Zrce",
                  "postalCode": "53291",
                  "addressRegion": "Novalja",
                  "addressCountry": "Croatia"
                }
              },
              "image": [
                "${img}"
              ],
              "description": "${metaDescription}"
            }
            `}
          </script>
        </Helmet>
      ) : null }

      {(noindex === true) ? (
        <Helmet>
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
      ) : null }

    </React.Fragment>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: `Zrce app by zrce.eu. Details event information and schedule for all Zrce festivals, clubs and genres`,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO