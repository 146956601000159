import React from "react"
import styled from "styled-components";

const MainLayout = styled.div`

`

const Main = ({ children }) => {
  return ( 
    <MainLayout>{children}</MainLayout>
  )  
}

export default Main